<template>
  <PDialog
    title="Bestilling"
    classes="w-10/12 md:w-4/5 xl:w-3/5"
    @close="onCancel"
    :loading="isLoading"
  >
    <div class="flex flex-1 overflow-y-hidden">

      <div class="flex flex-col p-4 overflow-y-auto bg-gray-100 border-r border-gray-300 space-y-4 w-96">

        <PDatePicker
          label="Leveringsdato"
          v-model="deliveryDate"
          :min="minDeliveryDate"
          :required="true"
        />

        <PInput
          label="Referanse"
          v-model="customerReference"
        />

        <PTextarea
          label="Kommentar"
          v-model="customerComment"
        />

        <PSelect
          label="Farge"
          :disabled="containerColors.length === 0"
          :items="containerColors"
          itemText="name"
          itemValue="id"
          v-model="containerColorId"
        />

      </div>

      <div class="flex flex-col flex-1 p-4 overflow-y-scroll">

        <div class="mb-1 text-sm">Containere</div>

        <div
          v-for="(containerType, index) in containerTypes"
          :key="index"
          class="flex items-center justify-between px-3 py-2 mb-2 border rounded-md"
          :class="{
            'bg-gradient-to-b from-gray-50 to-gray-200  border-gray-300': containerType.quantity === 0,
            'bg-green-100 border-green-500': containerType.quantity
          }"
        >
          <div class="flex items-center space-x-2">
            <FontAwesomeIcon
              :class="{
                'text-green-800': containerType.quantity > 0
              }"
              :icon="['far', 'container-storage']"
              size="lg"
            />
            <div class="font-medium">{{ containerType.name }}</div>
          </div>
          <div>
            <PNumber
              class="w-20"
              :max="100"
              v-model="containerType.quantity"
            />
          </div>
        </div>


      </div>
    </div>

    <PDialogActions>

      <PButton
        color="secondary"
        @click="onCancel"
        :disabled="isSubmitting"
      >
        Avbryt
      </PButton>

      <PButton
        class="ml-2"
        color="primary"
        @click="onSubmit"
        :disabled="!canSubmit"
        :loading="isSubmitting"
      >
        Send bestilling
      </PButton>

    </PDialogActions>
  </PDialog>
</template>

<script>

import http from "@/utilities/http"
import { forEach, map, sumBy } from "lodash"
import { mapGetters } from "vuex"
import { format } from "date-fns"

export default {

  data () {
    return {
      minDeliveryDate: format(new Date(), "yyyy-MM-dd"),
      deliveryDate: "",
      containerTypes: [],
      containerColors: [],
      isLoading: false,
      isSubmitting: false,
      customerReference: "",
      customerComment: "",
      containerColorId: ""
    }
  },

  computed: {

    ...mapGetters([
      "customer",
      "location",
      "user"
    ]),

    canSubmit () {
      return this.deliveryDate && sumBy(this.containerTypes, ct => ct.quantity) > 0
    }

  },

  methods: {

    onCancel () {
      this.$emit("close")
    },

    async onSubmit () {

      try {

        let promises = []

        forEach(this.containerTypes, containerType => {
          for (let i = 0, len = containerType.quantity; i < len; i++) {
            promises.push(
              http.post("ContainerOrder", {
                containerOrderTypeId: "TO",
                customerId: this.customer.id,
                locationId: this.location.id,
                containerTypeId: containerType.id,
                deliveryDate: this.deliveryDate,
                customerReference: "",
                customerComment: ""
              })
            )
          }
        })

        if (promises.length > 0) {
          await Promise.all(promises)
        }

        this.$notifySuccess("Ny containerbestilling")
        this.$emit("close")

      } catch (error) {
        this.$notifyError(error.reason)
      }

    },

    async init () {

      this.isLoading = true

      try {

        const containerTypes = await http.get("ContainerTypes")
        this.containerTypes = map(containerTypes, ct => {
          ct.quantity = 0
          return ct
        })

        this.containerColors = await http.get("ContainerColors")

      } catch (error) {
        this.$notifyError("", error)
      }

      this.isLoading = false

    }

  },

  async created () {
    await this.init()
  }

}

</script>
