<template>
  <div class="flex flex-col flex-1 overflow-y-scroll bg-white border border-gray-300 rounded" ref="scrollContainer">

    <PLoader v-if="isLoading" />

    <POrderGridTable
      v-else
      :cols="cols"
      :filters="filters"
      :orders="containerRentals"
      :totalCount="containerRentals.length"
      :orderByField="orderByField"
      :orderByDescending="orderByDescending"
      :loading="isLoading"
      :expandable="false"
      :showFooter="false"
      @click="onClick"
      @sort="onSort"
    >
    </POrderGridTable>

    <portal to="filters">

      <div class="flex items-center space-x-4">

        <PToggle
          :stacked="true"
          label="Bestilt"
          v-model="registeredVisible"
        />

        <PToggle
          :stacked="true"
          label="Registrert"
          v-model="openVisible"
        />

        <PToggle
          :stacked="true"
          label="Lukket"
          v-model="closedVisible"
        />

        <PDatePicker
          class="w-36"
          placeholder="Fra dato"
          v-model="dateLower"
          :clearable="true"
        />

        <PDatePicker
          class="w-36"
          placeholder="Til dato"
          v-model="dateUpper"
          :clearable="true"
        />

      </div>

    </portal>

    <portal to="actions">
      <PButton
        @click="createContainerOrdersDialogVisible = true"
        color="primary"
      >
        Bestill containere
      </PButton>
    </portal>

    <CreateContainerOrdersDialog
      v-if="createContainerOrdersDialogVisible"
      @close="onCloseCreateContainerOrdersDialog"
    />

  </div>
</template>

<script>

import http from "@/utilities/http"
import { mapGetters } from "vuex"
import {
  filter,
  orderBy
} from "lodash"
import POrderGridTable from "@/components/ordergrid/POrderGridTable"
import CreateContainerOrdersDialog from "../dialogs/CreateContainerOrdersDialog"

export default {

  components: {
    POrderGridTable,
    CreateContainerOrdersDialog,
  },

  data () {
    return {
      isLoading: false,
      allContainerRentals: [],
      createContainerOrdersDialogVisible: false,
      selectedOrderId: "",
      cols: [

        { text: "Leienr.", value: "id", component: "p-display-text", align: "center", width: 80, sortable: true },
        { text: "Startdato", value: "rentalFrom", component: "p-display-date", align: "center", width: 120, sortable: true },
        { text: "Sluttdato", value: "rentalTo", component: "p-display-date", align: "center", width: 120, sortable: true },
        { text: "Container type", value: "containerTypeName", component: "p-display-text", align: "left", sortable: true },
        { text: "Container ID", value: "containerId", component: "p-display-text", align: "left" },
        { text: "Nåværende lokasjon", value: "locationName", component: "p-display-text", align: "left", sortable: true },
        { text: "Siste aktivitet", value: "current", component: "p-display-container-order-current", align: "left" },
        { text: "Status", value: "rentalStatus", component: "p-display-container-rental-status", align: "center", width: 140, sortable: true },
      ],
      filters: {},
      // orderBy: {
      //   field: "dateArrived",
      //   descending: true
      // },
      orderByField: "rentalStatus",
      orderByDescending: false,
      registeredVisible: true,
      openVisible: true,
      closedVisible: false,
      dateLower: "",
      dateUpper: ""
    }
  },

  computed: {

    ...mapGetters([
      "customer",
      "location"
    ]),

    // Registered, Open, Closed

    containerRentals () {
      const filtered = filter(this.allContainerRentals, containerRental => {
            return (containerRental.rentalStatus === "Registered" && this.registeredVisible) ||
                   (containerRental.rentalStatus === "Open" && this.openVisible) ||
                   (containerRental.rentalStatus === "Closed" && this.closedVisible)
          })

      return orderBy(filtered, [this.orderByField], [this.orderByDescending ? "desc" : "asc"])
    },

    args () {
      return {
        locationId: this.location.id,
        dateLower: this.dateLower,
        dateUpper: this.dateUpper
      }
    }

  },

  methods: {

    onSort(col) {

      if (col.sortable) {

        this.$refs.scrollContainer.scrollTo(0, 0)

        this.orderByDescending =
          col.value === this.orderByField
            ? this.orderByDescending
              ? false
              : true
            : true

        this.orderByField = col.value
      }
    },

    onClick (containerRental) {
      this.$router.push({ name: "containers.Rental", params: { id: containerRental.id } })
    },

    async onCloseCreateContainerOrdersDialog () {
      this.createContainerOrdersDialogVisible = false
      await this.getContainerRentals()
    },

    async getContainerRentals () {

        this.isLoading = true

        try {

          let params = {}

          if (this.dateLower) {
            params.dateLower = this.dateLower
          }

          if (this.dateUpper) {
            params.dateUpper = this.dateUpper
          }

          this.allContainerRentals = await http.get("ContainerRentals", { params })

        } catch (error) {
          this.$notifyError("", error.reason)
        }

        this.isLoading = false
    }
  },


  watch: {
    args: {
      handler: async function () {
        await this.getContainerRentals ()
      }, immediate: true
    }
  }

}
</script>
